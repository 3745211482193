<template>
  <gmapMap ref="myMap" v-bind="{ ...$attrs, ...$props }" :options="myOptions" :class="classes">
    <slot />
  </gmapMap>
</template>

<script>
import { $autoZoom } from '../helper';
import Vue from 'vue';
import { map_apiKey } from '@/config/constants.js';
import * as VueGoogleMaps from 'vue2-google-maps';
import { dark, light } from './design';
Vue.use(VueGoogleMaps, { load: { key: map_apiKey, libraries: 'places,geometry' } });

export default {
  async mounted() {
    this.handleReady();
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return this.darkMode ? 'dark' : '';
    },
    mapObject() {
      return this.$refs.myMap.$mapObject;
    },
    myOptions() {
      const styles = this.darkMode ? dark : light;
      return { ...styles, ...this.options };
    }
  },
  methods: {
    _maplatLngExtendPath(latlngbounds, paths) {
      if (paths.lat) {
        return latlngbounds.extend(paths);
      } else {
        paths.map((path) => {
          latlngbounds = this.maplatLngExtendPath(latlngbounds, path);
        });
        return latlngbounds;
      }
    },
    autoZoom(bounds, adjustZoom = 0, maxZoom = '18') {
      $autoZoom({ map: this.mapObject, bounds, adjustZoom, maxZoom }, () => {
        let latlngbounds = new window.google.maps.LatLngBounds();
        bounds.map((bound) => (latlngbounds = this._maplatLngExtendPath(latlngbounds, bound)));
        this.mapObject.fitBounds(latlngbounds);
      });
    },
    async handleReady() {
      const result = await this.$refs.myMap.$mapPromise;
      this.$emit('ready', result);
    }
  },

  props: {
    center: { type: Object, default: () => {} },
    darkMode: { type: Boolean, default: false },
    options: { type: Object, default: () => {} },
    zoom: { type: Number, default: 10 }
  }
};
</script>

<style lang="sass" scoped>
$dark-50: #100f13
$dark-700: #2F2D34
$gray-800: #424047
$gray-500: #A6A1B2
.dark
  ::v-deep
    button
      background: $dark-700!important
      color: white!important
    &>div
      background-color: $dark-50!important
    .gm-bundled-control
      .gm-svpc,
      .gm-control-active
        background-color: $dark-700!important
        &+div
          background-color: $dark-50!important
      .gmnoprint>div:not(.gm-svpc)
        background-color: $dark-700!important
    .gm-style-iw
      background-color: $gray-800
      .gm-style-iw-d
        color: $gray-500
        margin-bottom: 0.5rem
        overflow: hidden !important
      .gm-ui-hover-effect
        background: url('~@/assets/img/close.svg') center no-repeat !important
        img
          display: none !important
    .gm-style-iw-t::after
      background: $gray-800 !important
</style>
